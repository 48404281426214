import { default as indextgbbN43YD0Meta } from "/codebuild/output/src1134994686/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45invite2zvZcaUk4iMeta } from "/codebuild/output/src1134994686/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchasewdNL6SkmDzMeta } from "/codebuild/output/src1134994686/src/pages/account/extra-purchase.vue?macro=true";
import { default as indexYkIwsSshHjMeta } from "/codebuild/output/src1134994686/src/pages/account/index.vue?macro=true";
import { default as settingsFApBRuKDNTMeta } from "/codebuild/output/src1134994686/src/pages/account/settings.vue?macro=true";
import { default as subscriptionu1BW7e4uFfMeta } from "/codebuild/output/src1134994686/src/pages/account/subscription.vue?macro=true";
import { default as transactionsykr1hQOhSuMeta } from "/codebuild/output/src1134994686/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionLmWwWh07bmMeta } from "/codebuild/output/src1134994686/src/pages/account/update-subscription.vue?macro=true";
import { default as previewdPZj7FGfGuMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composePtVps10KdGMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexzLVKTYCK37Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emails0nOqU6KCfSMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as editI5Z4NFxMcnMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsEAvno7UhGoMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as indexVDNUQCW2kIMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsK3TAlqXuj5Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesftJRl2KEnCMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as galleryt1y6wF6743Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsaeA2zOXKD5Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsNb5oQHbXh8Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indexKvvcmb7vuhMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as membersZ2YDkINF40Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as editmurwWtlML7Meta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexrphybyaMBPMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as newsZpSn3PC7soMeta } from "/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93optMVth06TMeta } from "/codebuild/output/src1134994686/src/pages/donate/[slug].vue?macro=true";
import { default as indexEmChmPfnr2Meta } from "/codebuild/output/src1134994686/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeUSNuUMuv4tMeta } from "/codebuild/output/src1134994686/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93WhQ03xkwUOMeta } from "/codebuild/output/src1134994686/src/pages/events/[slug].vue?macro=true";
import { default as indexf5AayzLFZmMeta } from "/codebuild/output/src1134994686/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordYsM3Mba1ngMeta } from "/codebuild/output/src1134994686/src/pages/forgot-password.vue?macro=true";
import { default as filesX7n5D4lZyVMeta } from "/codebuild/output/src1134994686/src/pages/groups/[slug]/files.vue?macro=true";
import { default as gallerytYjnTV9yolMeta } from "/codebuild/output/src1134994686/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexCFPbKMI2SZMeta } from "/codebuild/output/src1134994686/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopY55wI1NN4TMeta } from "/codebuild/output/src1134994686/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93d4McaT8KoKMeta } from "/codebuild/output/src1134994686/src/pages/groups/[slug].vue?macro=true";
import { default as index2G54oOnTq8Meta } from "/codebuild/output/src1134994686/src/pages/groups/index.vue?macro=true";
import { default as indexXWEOtC3N97Meta } from "/codebuild/output/src1134994686/src/pages/index.vue?macro=true";
import { default as joinRnjhSC4XyAMeta } from "/codebuild/output/src1134994686/src/pages/join.vue?macro=true";
import { default as login3ng8HzJErVMeta } from "/codebuild/output/src1134994686/src/pages/login.vue?macro=true";
import { default as confirmation3YTpObZEyGMeta } from "/codebuild/output/src1134994686/src/pages/membership/confirmation.vue?macro=true";
import { default as indexBQcLvKelKvMeta } from "/codebuild/output/src1134994686/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93HOVuynh7qsMeta } from "/codebuild/output/src1134994686/src/pages/news/[slug].vue?macro=true";
import { default as indexL8FWFwRoAwMeta } from "/codebuild/output/src1134994686/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordSeIJKHmILSMeta } from "/codebuild/output/src1134994686/src/pages/reset-password.vue?macro=true";
import { default as setup_452favzG4z0O2uuMeta } from "/codebuild/output/src1134994686/src/pages/setup-2fa.vue?macro=true";
import { default as shoprbVnSiZUatMeta } from "/codebuild/output/src1134994686/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src1134994686/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src1134994686/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchasewdNL6SkmDzMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexYkIwsSshHjMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsFApBRuKDNTMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionu1BW7e4uFfMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactionsykr1hQOhSuMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionLmWwWh07bmMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/account/update-subscription.vue")
  },
  {
    name: emails0nOqU6KCfSMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emails0nOqU6KCfSMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: previewdPZj7FGfGuMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composePtVps10KdGMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexzLVKTYCK37Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsK3TAlqXuj5Meta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsK3TAlqXuj5Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: editI5Z4NFxMcnMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsEAvno7UhGoMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: indexVDNUQCW2kIMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesftJRl2KEnCMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: galleryt1y6wF6743Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsaeA2zOXKD5Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsNb5oQHbXh8Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indexKvvcmb7vuhMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: membersZ2YDkINF40Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: newsZpSn3PC7soMeta?.name,
    path: "/admin/groups/:slug()/news",
    meta: newsZpSn3PC7soMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: editmurwWtlML7Meta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexrphybyaMBPMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src1134994686/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src1134994686/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src1134994686/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src1134994686/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src1134994686/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src1134994686/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93d4McaT8KoKMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src1134994686/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src1134994686/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src1134994686/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src1134994686/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src1134994686/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: indexBQcLvKelKvMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src1134994686/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src1134994686/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src1134994686/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452favzG4z0O2uuMeta || {},
    component: () => import("/codebuild/output/src1134994686/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src1134994686/src/pages/shop.vue")
  }
]